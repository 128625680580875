<template>
  <el-dialog :title="$t('form.activity.activityProduct')" :visible="visible" width="800px" :close-on-click-modal="false" :close-on-press-escape="false" @close="handleDialogClose">
    <el-form ref="mainForm" :model="mainForm.data" :rules="mainForm.rules" label-width="140px">
      <el-form-item :label="$t('form.activity.online')" prop="online">
        <el-switch v-model="mainForm.data.online" />
      </el-form-item>
      <el-form-item :label="$t('form.activity.freePostage')" prop="free_delivery">
        <el-switch v-model="mainForm.data.free_delivery" />
      </el-form-item>
      <el-form-item v-if="activity && (activity.activity_type === dataDictionary['001']['001'].code || activity.activity_type === dataDictionary['001']['003'].code) && visible" :label="$t('form.activity.cover')">
        <importImg ref="mainFormImportImg" :limit="1" :data="mainForm.cover" @change="handleMainFormCoverChange" />
      </el-form-item>
      <el-form-item :label="$t('form.activity.purchaseLimitOfTheSameUser')" prop="maximum_limit_per_user">
        <el-input-number v-model="mainForm.data.maximum_limit_per_user" :placeholder="$t('placeholder.defaultInput')" controls-position="right" :min="1"></el-input-number>
      </el-form-item>
      <el-form-item v-if="activity && activity.activity_type === dataDictionary['001']['004'].code" :label="$t('form.activity.flashSaleSessions')" prop="shop_activity_session_ids">
        <el-checkbox-group v-if="activity" v-model="mainForm.data.shop_activity_session_ids">
          <el-checkbox v-for="(item, index) in activity.shop_activity_sessions" :key="index" :label="item.id" name="shopActivitySessionIds">{{ `${dateFormat(item.begin_time)} ~ ${dateFormat(item.end_time)}` }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item v-if="activity && activity.activity_type === dataDictionary['001']['005'].code" :label="$t('form.activity.slashSaleCount')" prop="number_of_slashes">
        <el-input-number v-model="mainForm.data.number_of_slashes" :placeholder="$t('placeholder.defaultInput')" controls-position="right" :min="1"></el-input-number>
      </el-form-item>
      <el-form-item v-if="activity && activity.activity_type === dataDictionary['001']['006'].code" :label="$t('form.activity.minimumGroupSize')" prop="minimum_group_size">
        <el-input-number v-model="mainForm.data.minimum_group_size" :placeholder="$t('placeholder.defaultInput')" controls-position="right" :min="1"></el-input-number>
      </el-form-item>
      <el-form-item v-if="activity && (activity.activity_type === dataDictionary['001']['005'].code || activity.activity_type === dataDictionary['001']['006'].code)" :label="$t('form.activity.timeWindow')" prop="time_window">
        <el-input-number v-model="mainForm.data.time_window" :placeholder="$t('placeholder.defaultInput')" controls-position="right" :min="1"></el-input-number>
      </el-form-item>
      <el-form-item v-if="activity && (activity.activity_type === dataDictionary['001']['005'].code || activity.activity_type === dataDictionary['001']['006'].code)" :label="$t('form.activity.startEndTime')" prop="date">
        <el-date-picker v-model="mainForm.data.date" type="datetimerange" range-separator="-" :start-placeholder="$t('form.activity.startTime')" :end-placeholder="$t('form.activity.endTime')" />
      </el-form-item>
      <el-form-item v-if="activity && (activity.activity_type === dataDictionary['001']['004'].code || activity.activity_type === dataDictionary['001']['005'].code || activity.activity_type === dataDictionary['001']['006'].code)" :label="$t('form.activity.participatingModel')">
        <el-table
          ref="mainFormTable"
          style="width: 100%"
          :data="stock"
          @selection-change="handleMainFormTableSelectionChange">
          <el-table-column
            :label="$t('table.activity.participate')"
            type="selection">
          </el-table-column>
          <el-table-column
            :label="$t('table.activity.modifier')"
            prop="name">
          </el-table-column>
          <el-table-column
            :label="$t('table.activity.sellingPrice')"
            prop="price">
            <template slot-scope="scope">
              <div>{{ scope.row.price_f }}</div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="activity.activity_type === dataDictionary['001']['004'].code"
            :label="$t('table.activity.campaignPrice')"
            prop="cost_price">
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.input_price" :placeholder="$t('placeholder.defaultInput')" :precision="2" controls-position="right" :min="0"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column
            v-if="activity.activity_type === dataDictionary['001']['005'].code"
            :label="$t('table.activity.lowestPrice')"
            prop="cost_price">
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.input_price" :placeholder="$t('placeholder.defaultInput')" :precision="2" controls-position="right" :min="0"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column
            v-if="activity.activity_type === dataDictionary['001']['006'].code"
            :label="$t('table.activity.groupBuyPrice')"
            prop="cost_price">
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.input_price" :placeholder="$t('placeholder.defaultInput')" :precision="2" controls-position="right" :min="0"></el-input-number>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleDialogClose">{{ $t('button.cancel') }}</el-button>
      <el-button type="primary" @click="handleMainFormSubmitClick">{{ $t('button.confirm') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Utils from '@/utils/Utils'
import loading from '@/utils/loading.json'
import importImg from '@/components/importImg.vue'
import dataDictionary from '@/utils/data-dictionary.js'
import * as promotionApi from '@/services/promotion.js'

export default {
  name: 'addProductDialog',
  components: {
    importImg
  },
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    productId: {
      type: [Number, String],
      default: () => ''
    },
    activity: {
      type: Object,
      default: () => {}
    },
    setVisible: {
      type: Function,
      default: () => false
    },
    type: {
      type: [Number, String],
      default: () => 0
    },
    data: {
      type: Object,
      default: () => null
    },
    stock: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dataDictionary,
      mainForm: {
        cover: [],
        selectedRow: [],
        rules: {
          online: [
            { required: true, message: this.$t('text.required'), trigger: 'blur' }
          ],
          free_delivery: [
            { required: true, message: this.$t('text.required'), trigger: 'blur' }
          ],
          maximum_limit_per_user: [
            { required: false, message: this.$t('text.required'), trigger: 'blur' }
          ],
          shop_activity_session_ids: [
            { required: true, message: this.$t('text.required'), trigger: 'blur' }
          ],
          number_of_slashes: [
            { required: true, message: this.$t('text.required'), trigger: 'blur' }
          ],
          minimum_group_size: [
            { required: true, message: this.$t('text.required'), trigger: 'blur' }
          ],
          date: [
            { required: true, message: this.$t('text.required'), trigger: 'blur' }
          ],
          time_window: [
            { required: true, message: this.$t('text.required'), trigger: 'blur' }
          ]
        },
        data: this.getDefaultMainFormData()
      }
    }
  },
  watch: {
    visible (newV, oldV) {
      const _this = this
      if (newV) {
        if (_this.type === 1) {
          _this.mainForm.data.online = _this.data.online
          _this.mainForm.data.remote_cover_image_url = _this.data.cover_image_url
          _this.mainForm.data.free_delivery = _this.data.free_delivery
          _this.mainForm.data.maximum_limit_per_user = _this.data.maximum_limit_per_user ? _this.data.maximum_limit_per_user : undefined
          _this.mainForm.data.shop_activity_session_ids = _this.data.shop_activity_session_ids || []
          _this.mainForm.data.number_of_slashes = _this.data.number_of_slashes ? _this.data.number_of_slashes : undefined
          _this.mainForm.data.time_window = _this.data.slash_time_window ? _this.data.slash_time_window : undefined
          if (_this.data.begin_date) {
            _this.mainForm.data.date[0] = _this.data.begin_date
          }
          if (_this.data.end_date) {
            _this.mainForm.data.date[1] = _this.data.end_date
          }
          _this.mainForm.data.minimum_group_size = _this.data.minimum_group_size ? _this.data.minimum_group_size : undefined
          _this.mainForm.cover = [{ id: _this.data.cover_image_url, image_url: _this.data.cover_image_url }]
          const tmpStocks = []
          _this.data.item_stocks.forEach(item => {
            for (const index in _this.stock) {
              const subItem = _this.stock[index]
              if (subItem.id === item.id) {
                subItem.input_price = item.campaign_price / 100
                tmpStocks.push(subItem)
                break
              }
            }
          })
          _this.$nextTick(() => {
            tmpStocks.forEach(item => {
              _this.$refs.mainFormTable.toggleRowSelection(item)
            })
          })
        } else {
          _this.mainForm.data = _this.getDefaultMainFormData()
          _this.mainForm.cover = [{ id: new Date().getTime(), image_url: null }]
        }
      }
    }
  },
  methods: {
    getDefaultMainFormData () {
      return {
        online: true, // 上架
        remote_cover_image_url: null, // 商品封面
        free_delivery: false, // 是否免邮
        shop_item_id: null, // 商品 id
        maximum_limit_per_user: undefined, // 同一用户购买上限
        shop_activity_session_ids: [], // 秒杀活动场次
        number_of_slashes: undefined, // 砍价次数
        minimum_group_size: undefined, // 最小拼团人数
        time_window: undefined, // 时间窗口
        date: []
      }
    },
    dateFormat (date) {
      return Utils.dateFormat('YYYY-mm-dd HH:MM:SS', date)
    },
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = this.$loading(loading) : this.$store.state.app.loading.close()
    },
    handleDialogClose () {
      const _this = this
      _this.setVisible(false)
      // _this.$refs.mainFormImportImg.clear()
      _this.$emit('onCancel')
    },
    handleMainFormCoverChange (fileData) {
      const _this = this
      if (fileData && fileData.length > 0) {
        if (fileData[0]._destroy) {
          _this.mainForm.data.remote_cover_image_url = null
        } else {
          _this.mainForm.data.remote_cover_image_url = fileData[0].url
        }
      } else {
        _this.mainForm.data.remote_cover_image_url = null
      }
    },
    handleMainFormTableSelectionChange (selection) {
      const _this = this
      _this.mainForm.selectedRow = selection
    },
    handleMainFormSubmitClick () {
      const _this = this
      _this.$refs.mainForm.validate((valid) => {
        if (valid) {
          const requestParams = {
            shop_activity_item: {
              ..._this.mainForm.data,
              shop_item_id: _this.productId
            }
          }
          if (requestParams.shop_activity_item.date && requestParams.shop_activity_item.date.length > 0) {
            requestParams.shop_activity_item.begin_date = requestParams.shop_activity_item.date[0]
            requestParams.shop_activity_item.end_date = requestParams.shop_activity_item.date[1]
            delete requestParams.shop_activity_item.date
          }
          if (_this.activity.activity_type === dataDictionary['001']['004'].code || _this.activity.activity_type === dataDictionary['001']['005'].code || _this.activity.activity_type === dataDictionary['001']['006'].code) {
            if (_this.mainForm.selectedRow.length < 1) {
              _this.$message({
                type: 'warning',
                message: _this.$t('form.activity.submitShopActivitiesItemsWarning1')
              })
              return false
            }
            for (const index in _this.mainForm.selectedRow) {
              if (_this.mainForm.selectedRow[index].input_price === null || _this.mainForm.selectedRow[index].input_price === undefined) {
                _this.$message({
                  type: 'warning',
                  message: _this.$t('form.activity.submitShopActivitiesItemsWarning2')
                })
                return false
              }
            }
            requestParams.shop_activity_item.stocks = []
            _this.mainForm.selectedRow.forEach(item => {
              requestParams.shop_activity_item.stocks.push({
                id: item.id,
                price: item.input_price,
                participate: true
              })
            })
          }
          if (requestParams.shop_activity_item.maximum_limit_per_user === undefined) {
            requestParams.shop_activity_item.maximum_limit_per_user = ''
          }
          if (requestParams.shop_activity_item.number_of_slashes === undefined) {
            requestParams.shop_activity_item.number_of_slashes = ''
          }
          if (requestParams.shop_activity_item.time_window === undefined) {
            requestParams.shop_activity_item.time_window = ''
          }
          if (requestParams.shop_activity_item.minimum_group_size === undefined) {
            requestParams.shop_activity_item.minimum_group_size = ''
          }
          if (_this.type === 0) {
            _this.setGlobalLoading(true)
            promotionApi.postShopActivitiesIdItems(_this.activity.id, requestParams).then(res => {
              _this.setGlobalLoading(false)
              _this.$message({
                type: 'success',
                message: _this.$t('message.operateSuccess')
              })
              _this.$emit('onSuccess')
            }).catch(() => _this.setGlobalLoading(false))
          } else {
            _this.setGlobalLoading(true)
            promotionApi.putShopActivitiesIdItemsId(_this.activity.id, _this.productId, requestParams).then(res => {
              _this.setGlobalLoading(false)
              _this.$message({
                type: 'success',
                message: _this.$t('message.operateSuccess')
              })
              _this.$emit('onSuccess')
            }).catch(() => _this.setGlobalLoading(false))
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
